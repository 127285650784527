import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'



const Sidebar = () => {
  const navigate = useNavigate()

  const logOut = () => {
    localStorage.clear()
    navigate('/login')
    Swal.fire({
      icon: 'success',
      text: 'Logout successfully',
    })
  }

  return (
    <div className="w-1/5 relative bg-white h-screen flex-col justify-between flex">
      <div className="px-8">
        <div className="h-16 w-full flex items-center justify-center mt-10">
          <p className="text-center text-3xl font-semibold text-cyan-700">Vanko</p>
        </div>
        <ul className="mt-12">
          <li className="flex h-10 w-full px-2 items-center text-accent-content hover:bg-gray-100 rounded cursor-pointer mb-6">
            <ion-icon name="bag"></ion-icon>
            <Link to="/" className='text-sm ml-2 w-full h-full flex items-center'> Products</Link>
          </li>
          <li className="flex h-10 w-full px-2 text-accent-content hover:bg-gray-100 rounded cursor-pointer items-center mb-6">
            <ion-icon name="grid"></ion-icon>
            <Link to="/categories" className='text-sm ml-2 w-full h-full flex items-center'>Categories</Link>
          </li>
          <li className="flex h-10 w-full px-2 text-accent-content hover:bg-gray-100 rounded cursor-pointer items-center mb-6">
            <ion-icon name="person-add"></ion-icon>
            <Link to="/register" className='text-sm ml-2 w-full h-full flex items-center'>Register Admin</Link>
          </li>
          <li
            onClick={() => logOut()}
            className="flex h-10 w-full px-2 text-accent-content hover:bg-gray-100 rounded cursor-pointer items-center mb-6"
          >
            <ion-icon name="log-out"></ion-icon>
            <span className="text-sm ml-2">Sign Out</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar