import { useState, useEffect } from 'react'
import { addProduct, fetchProducts } from '../store/actions/productAction'
import { fetchCategories } from '../store/actions/categoryAction'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const ProductForm = (props) => {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories.categories)

  useEffect(() => dispatch(fetchCategories()), [])

  const closeModal = () => props.setShowProductForm(false)

  const [product, setProduct] = useState({
    name: "",
    price: "",
    categoryId: "",
    description: "",
    mainImg: "",
    image1: "",
    image2: "",
    image3: ""
  })

  const formOnchangeHandler = (e) => {
    const obj = { ...product }
    obj[e.target.name] = e.target.value
    setProduct(obj)
  }

  const addProductHandler = () => {
    console.log(product);
    if (product.categoryId === "") {
      return Swal.fire({
        icon: 'error',
        text: "category canot be empty",
      })
    }
    if (product.image1 === "" || product.image2 === "" || product.image3 === "") {
      return Swal.fire({
        icon: 'error',
        text: "All image must be filled",
      })
    }
    dispatch(addProduct(product))
      .then(async res => {
        if (!res.ok) throw { message: await res.json() }

        const msg = await res.json()
        Swal.fire({
          icon: 'success',
          text: msg.message,
        })
        dispatch(fetchProducts())
        closeModal()
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          text: err.message.message,
        })
      })
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/3 h-3/4 overflow-auto my-6 mx-auto bg-white">
          <div className="relative p-6 flex-auto w-full h-5/6 overflow-auto">
            <form className='space-y-2'>
              <div>
                <span className='text-xs'>Product Name</span>
                <input type="text" name="name" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
              </div>

              <div>
                <span className='text-xs'>Price</span>
                <input type="number" name="price" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
              </div>

              <div>
                <span className='text-xs'>Category</span>
                <select name="categoryId" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300'>
                  <option value="" hidden>Select your category</option>
                  {categories.map(el => <option value={el.id} key={el.id}>{el.name}</option>)}
                </select>
              </div>

              <div>
                <span className='text-xs'>Description</span>
                <textarea name="description" onChange={formOnchangeHandler} className='w-full h-24 bg-gray-100 rounded border border-slate-300'></textarea>
              </div>

              <div className='space-y-2'>
                <span className='text-xs'>Image Url</span>
                <input type="text" name="mainImg" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
                <input type="text" name="image1" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
                <input type="text" name="image2" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
                <input type="text" name="image3" onChange={formOnchangeHandler} className='w-full h-9 bg-gray-100 rounded border border-slate-300' />
              </div>
            </form>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                addProductHandler()
              }}
            >
              Add Product
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ProductForm