import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { registerHandler } from '../store/actions/actionCreator';

const Register = () => {
  const dispatch = useDispatch()
  const [registerFormData, setRegisterFormData] = useState({
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
  })

  const formOnchangeHandler = (e) => {
    const obj = { ...registerFormData }
    obj[e.target.name] = e.target.value
    setRegisterFormData(obj)
    console.log(registerFormData);
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(registerHandler(registerFormData))
      .then(async res => {
        if (!res.ok) throw { message: await res.json() }
        Swal.fire({
          icon: 'success',
          text: 'New admin added'
        })
        document.getElementById("registerForm").reset();
      })
      .catch(err => {
        console.log(err);
        return Swal.fire({
          icon: 'error',
          text: err.message.message
        })
      })
  }

  return (
    <div className="w-full px-6 h-full pt-24">
      {/* Header */}
      <div className="px-4 w-full md:px-10 py-4 md:py-7 bg-slate-200 rounded-tl-lg rounded-tr-lg">
        <div className="sm:flex items-center justify-between">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Add new admin</p>
        </div>
      </div>

      <div className="bg-white px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto overflow-x-auto h-5/6">
        <div className="mx-auto w-1/3 h-11/12 p-10 bg-white border border-gray-200 rounded-lg">
          <form onSubmit={submitHandler} id="registerForm">
            <span className="text-sm">Username</span>
            <input name="username" onChange={formOnchangeHandler} type="text" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />

            <span className="text-sm">Email</span>
            <input name="email" onChange={formOnchangeHandler} type="text" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />

            <span className="text-sm">Phone Number</span>
            <input name="phoneNumber" onChange={formOnchangeHandler} type="number" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />

            <span className="text-sm">Address</span>
            <textarea name="address" onChange={formOnchangeHandler} type="text" className="w-full h-16 border bg-accent px-4 py-1 rounded bg-gray-100"></textarea>

            <span className="text-sm">Password</span>
            <input name="password" onChange={formOnchangeHandler} type="password" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />

            <button type='submit' className="w-full h-8 mt-5 rounded bg-indigo-700 hover:bg-indigo-600 focus:outline-none text-white text-sm">Register</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Register