import Image from "./Image"
import { useEffect, useState } from "react"
import { fetchImages } from "../store/actions/imagesAction"
import { useDispatch } from "react-redux"

const Images = (props) => {
  const dispatch = useDispatch()
  const closeModal = () => props.setShowImagesModal(false)
  const [images, setImages] = useState([])

  useEffect(() => {
    _fetchImages()
  }, [])

  const _fetchImages = () => {
    dispatch(fetchImages(props.productId))
      .then(res => {
        if (!res.ok) {
          throw { message: "Error" }
        }
        return res.json()
      })
      .then(data => {
        setImages(data.data)
      })
      .catch(console.log)
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 h-3/4 overflow-auto mx-auto bg-white">
          <div className="relative p-4 flex-auto w-full h-5/6 overflow-auto space-y-5">
            {
              images.map(el => {
                return (
                  <Image image={el} fetchImages={() => _fetchImages()} />
                )
              })
            }
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end px-6 pt-3 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default Images