import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { editImage } from "../store/actions/imagesAction"

const Image = (props) => {
  const dispatch = useDispatch()
  const [imgUrl, setImgUrl] = useState('')
  const [formStatus, setFormStatus] = useState(true)

  useEffect(() => {
    setImgUrl(props.image.imgUrl)
  }, [])

  const enableEdit = () => {
    setFormStatus(false)
  }

  const submitEdit = () => {
    dispatch(editImage(props.image.id, imgUrl))
      .then(res => {
        if (!res.ok) throw { message: "Edit failed" }
        return res.json()
      })
      .then(() => {
        props.fetchImages()
        console.log("success");
      })
      .catch(console.log)
      
    setFormStatus(true)
  }

  return (
    <div className="w-full h-96 bg-gray-100 p-5 space-y-2 rounded-md">
      <div className="w-86 h-56">
        <div className="w-80 h-48 bg-gray-200 bg-cover mx-auto" style={{ backgroundImage: `url("${props.image.imgUrl}")` }} />
      </div>

      <div className="h-28">
        <div className="w-full flex justify-center">
          <span className="text-sm">ImgUrl</span>
        </div>
        <div className="w-full h-12  flex justify-center">
          <input
            type="text"
            value={imgUrl}
            onChange={(e) => { setImgUrl(e.target.value) }}
            disabled={formStatus}
            className="w-3/4 h-10 border bg-accent px-4 py-1 rounded bg-gray-200 mx-auto"
          />
        </div>
        <div className="w-full flex justify-center">
          {
            formStatus ? (
              <button onClick={enableEdit} type='submit' className="w-24 h-8 rounded bg-amber-500 hover:bg-amber-400 focus:outline-none text-white text-sm">edit</button>
            ) : (
              <button onClick={submitEdit} type='submit' className="w-24 h-8 rounded bg-indigo-700 hover:bg-indigo-600 focus:outline-none text-white text-sm">Submit</button>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Image