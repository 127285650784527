import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { addCategory, fetchCategory, editCategory } from '../store/actions/categoryAction'
import { fetchCategories } from '../store/actions/categoryAction'

const CategoryForm = (props) => {
  const dispatch = useDispatch()
  const closeModal = () => props.setShowCategoryForm(false)
  const _category = useSelector(state => state.categories.category)
  const [category, setCategory] = useState("")

  useEffect(() => {
    if (props.action === "edit") dispatch(fetchCategory(props.categoryId))
  }, [])

  useEffect(() => {
    if (_category.data && props.action === "edit") setCategory(_category.data.name)
  }, [_category])

  const submitHandler = (e) => {
    if (props.action === "edit") {
      e.preventDefault()
      dispatch(editCategory(props.categoryId, category))
        .then(async res => {
          if (!res.ok) throw { message: await res.json() }

          const msg = await res.json()
          Swal.fire({
            icon: 'success',
            text: msg.data,
          })
          dispatch(fetchCategories())
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message.message.name,
          })
        })
      return closeModal()
    }
    e.preventDefault()
    dispatch(addCategory(category))
      .then(async res => {
        if (!res.ok) throw { message: await res.json() }

        const msg = await res.json()
        Swal.fire({
          icon: 'success',
          text: msg.message,
        })
        dispatch(fetchCategories())
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: err.message.message.name,
        })
      })
      closeModal()
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/3 h-1/4 overflow-auto mx-auto bg-white">
          <form className='space-y-2' onSubmit={submitHandler}>
            <div className="relative p-4 flex-auto w-full h-3/5 overflow-auto">
              <span className='text-xs'>Category Name</span>
              <input type="text" value={category} onChange={(e) => {
                setCategory(e.target.value)
              }} className='px-2 w-full h-9 bg-gray-100 rounded border border-slate-300' />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end px-6 pt-3 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Save Changes
              </button>

            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default CategoryForm