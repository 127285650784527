import { Routes, Route } from "react-router-dom";
import Products from "./pages/Products";
import Category from "./pages/Category";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Protected from "./component/Protected";
import ProtectedLogin from "./component/ProtectedLogin";


const App = () => {
  return (
    <Routes>
      <Route path='/'element={<Protected> <Dashboard /> </Protected>}>
        <Route index={true} element={<Products />} />
        <Route path="categories" element={<Category />} />
        <Route path="register" element={<Register />} />
      </Route>

      <Route path='/login' element={
          <ProtectedLogin>
            <Login />
          </ProtectedLogin>
        } />
    </Routes>
  )
}

export default App;
