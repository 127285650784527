const TableRowProduct = ({ data, openImagesModal, deleteProduct, openEditProductForm}) => {
  const { id, name, price, Category, User, mainImg } = data
  return (
    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
      <td className="text-center">{name}</td>
      <td className="text-center">{Category.name}</td>
      <td className="text-center">{price.toLocaleString('en-US')}</td>
      <td className="text-center">{User.username}</td>
      <td className="text-center">
        <img className="inline object-cover w-16 h-16 mr-2 rounded-full" src={mainImg} />
      </td>
      <td className="text-center">
        <button 
          onClick={() => openImagesModal(id)}
          className="px-2 py-1.5 rounded bg-indigo-700 hover:bg-indigo-600 focus:outline-none text-white text-sm">
          images
        </button>
      </td>
      <td className="text-center space-x-2">
        <button 
        onClick={() => openEditProductForm(id)}
        className="px-2 py-1.5 rounded bg-amber-500 hover:bg-amber-400 text-white text-sm">
          Edit
        </button>
        <button 
        onClick={() => deleteProduct(id)}
        className="px-2 py-1.5 rounded bg-red-600 hover:bg-red-500 text-white text-sm">
          Delete
        </button>
      </td>
    </tr>
  )
}

export default TableRowProduct