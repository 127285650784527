import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts, deleteProduct } from '../store/actions/productAction'
import EditProductForm from '../component/EditProductForm';
import ProductForm from '../component/ProductForm';
import TableRowProduct from '../component/TableRowProduct';
import Images from "../component/Images"
import Swal from 'sweetalert2';

const Products = () => {
  const dispatch = useDispatch()
  const products = useSelector(state => state.products.products)
  const [showAddProductForm, setShowAddProductForm] = useState(false)
  const [showEditProductForm, setShowEditProductForm] = useState(false)
  const [imagesModal, setShowImagesModal] = useState(false)
  const [productId, setproductId] = useState()

  useEffect(() => {
    dispatch(fetchProducts())
  }, [])

  const openEditForm = (id) => {
    setproductId(id)
    setShowEditProductForm(true)
  }

  const openImagesModal = (id) => {
    setproductId(id)
    setShowImagesModal(true)
  }

  return (
    <div className="w-full px-6 h-full pt-24">
      {
        showAddProductForm ? (
          <ProductForm
            setShowProductForm={setShowAddProductForm} />
        ) : null
      }

      {
        showEditProductForm ? (
          <EditProductForm
            productId={productId}
            setShowEditProductForm={setShowEditProductForm} />
        ) : null
      }

      {
        imagesModal ? (
          <Images productId={productId} setShowImagesModal={setShowImagesModal} />
        ) : null
      }
      {/* Header */}
      <div className="px-4 w-full md:px-10 py-4 md:py-7 bg-slate-200 rounded-tl-lg rounded-tr-lg">
        <div className="sm:flex items-center justify-between">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Products</p>
          <div>
            <button onClick={() => setShowAddProductForm(true)} className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
              <p className="text-sm font-medium leading-none text-white">New Product</p>
            </button>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto overflow-x-auto h-5/6">
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-gray-800">
              <th className="font-normal text-center">Name</th>
              <th className="font-normal text-center">Category</th>
              <th className="font-normal text-center">Price</th>
              <th className="font-normal text-center">Created By</th>
              <th className="font-normal text-center">Main Image</th>
              <th className="font-normal text-center">Images</th>
              <th className="font-normal text-center">Action</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {
              products.map(el => {
                return (
                  <TableRowProduct
                    key={el.id}
                    data={el}
                    openImagesModal={openImagesModal}
                    openEditProductForm={openEditForm}
                    deleteProduct={() => {
                      dispatch(deleteProduct(el.id))
                        .then(async res => {
                          if (!res.ok) throw { message: await res.json() }

                          const msg = await res.json()
                          dispatch(fetchProducts())
                          Swal.fire({
                            icon: 'success',
                            text: msg.data,
                          })
                        })
                        .catch(err => {
                          Swal.fire({
                            icon: 'error',
                            text: err.message.message.name,
                          })
                        })
                    }}
                  />
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Products