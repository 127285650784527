import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CategoryForm from '../component/CategoryForm';
import TableRowCategory from '../component/TableRowCategory';
import Swal from 'sweetalert2'
import { deleteCategory, fetchCategories } from '../store/actions/categoryAction';

const Category = () => {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories.categories)
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false)
  const [showEditCategoryForm, setShowEditCategoryForm] = useState(false)
  const [categoryToEdit, setCategoryToEdit] = useState()


  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  const deleteCategoryHandler = (id) => {
    dispatch(deleteCategory(id))
    .then(async res => {
      if (!res.ok) throw { message: await res.json() }

      const msg = await res.json()
    dispatch(fetchCategories())
      Swal.fire({
        icon: 'success',
        text: msg.data,
      })
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: err.message.message.name,
      })
    })
  }

  const editCategoryHandler = (id) => {
    setCategoryToEdit(id)
    setShowEditCategoryForm(true)
  }

  return (
    <div className="w-full px-6 h-full pt-24">
      {showAddCategoryForm ? (
        <CategoryForm 
        setShowCategoryForm={setShowAddCategoryForm} action="add"/>
      ) : null}

      {showEditCategoryForm ? (
        <CategoryForm 
        setShowCategoryForm={setShowEditCategoryForm} action="edit" categoryId={categoryToEdit}/>
      ) : null}

      <div className="px-4 w-full md:px-10 py-4 md:py-7 bg-slate-200 rounded-tl-lg rounded-tr-lg">
        <div className="sm:flex items-center justify-between">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Categories</p>
          <div>
            <button onClick={() => setShowAddCategoryForm(true)} className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
              <p className="text-sm font-medium leading-none text-white">New Category</p>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto overflow-x-auto h-5/6">
        <table className="w-full">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-gray-800">
              <th className="font-semibold text-center">NO</th>
              <th className="font-semibold text-center">NAME</th>
              <th className="font-semibold text-center">CREATED AT</th>
              <th className="font-semibold text-center">UPDATED AT</th>
              <th className="font-semibold text-center">ACTION</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {
              categories.map((el, i) => {
                return (
                  <TableRowCategory 
                  key={el.id}
                  data={el}
                  num={i + 1}
                  editCategory={editCategoryHandler}
                  deleteCategory={deleteCategoryHandler}/>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Category