import { FETCH_PRODUCT, FETCH_PRODUCTS } from "../actions/actionType"

const fetchProducts = () => {
  return (dispatch) => {
    fetch('https://vanko.herokuapp.com/products', {
      method: 'get',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
      .then(res => {
        if (!res.ok) {
          throw { message: "Error" }
        }
        return res.json()
      })
      .then(data => dispatch(fetchProductsSuccess(data.data)))
  }
}

const fetchProduct = (id) => {
  return (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/product/${id}`, {
      method: 'get',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
  }
}

const addProduct = (product) => {
  return async (dispatch) => {
    return fetch('https://vanko.herokuapp.com/products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify(product)
    });
  }
}

const deleteProduct = (id) => {
  return async (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/products/${id}`, {
      method: 'DELETE',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    });
  }
}

const editProduct = (id, data) => {
  return async (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/products/${id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify(data)
    });
  }
} 

const fetchProductsSuccess = (data) => {
  return {
    type: FETCH_PRODUCTS,
    data: data
  }
}

const fetchProductSuccess = (data) => {
  return {
    type: FETCH_PRODUCT,
    data: data
  }
}

export {
  fetchProduct,
  fetchProducts,
  addProduct,
  deleteProduct,
  editProduct
}