import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginHandler } from '../store/actions/actionCreator'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'


const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })

  const login = (e) => {
    e.preventDefault()
    dispatch(loginHandler(formData))
      .then(async res => {
        if (!res.ok) throw { message: await res.json() }
        return res.json()
      })
      .then(data => {
        localStorage.setItem('access_token', data.data.accessToken)
        navigate('/')
        Swal.fire({
          icon: 'success',
          text: 'Login successfully',
        })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: err.message.message.name,
        })
      })
  }

  return (
    <form onSubmit={login}>
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="w-1/2 rounded-lg shadow-xl p-10 md:flex">
          <div className="my-auto md:pr-10">
            <h1 className="text-4xl font-bold">Login</h1>
            <h3 className="text-sm mt-1">Please login to your account first</h3>
          </div>

          <div className="border-b pb-3 mb-2 md:pb-0 md:mb-0 md:border-l md:h-auto"></div>

          <div className="md:pl-10 my-auto">
            <span className="text-sm">Email</span>
            <input onChange={(e) => {
              setFormData({ ...formData, email: e.target.value })
            }} type="text" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />

            <span className="text-sm">Password</span>
            <input onChange={(e) => {
              setFormData({ ...formData, password: e.target.value })
            }} type="password" className="w-full border bg-accent px-4 py-1 rounded bg-gray-100" />
            <button type='submit' className="w-full h-8 mt-5 rounded bg-indigo-700 hover:bg-indigo-600 focus:outline-none text-white text-sm">Login</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Login