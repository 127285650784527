import Swal from 'sweetalert2'

const loginHandler = (loginData) => {
  return async (dispatch) => {
    return fetch('https://vanko.herokuapp.com/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginData)
    })
  }
}

const registerHandler = (registerData) => {
  return async (dispatch) => {
    return fetch('https://vanko.herokuapp.com/register', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify(registerData)
    })
  }
}

export { loginHandler, registerHandler}