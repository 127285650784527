import { FETCH_CATEGORIES, FETCH_CATEGORY } from "../actions/actionType"

const fetchCategories = () => {
  return (dispatch) => {
    fetch('https://vanko.herokuapp.com/categories', {
      method: 'get',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
      .then(res => {
        if (!res.ok) {
          throw { message: "Error" }
        }
        return res.json()
      })
      .then(data => dispatch(fetchCategoriesSuccess(data.data)))
  }
}

const deleteCategory = (id) => {
  return (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/categories/${id}`, {
      method: 'DELETE',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
  }
}

const addCategory = (name) => {
  return async (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify({
        name
      })
    })
  }
}

const fetchCategory = (id) => {
  return (dispatch) => {
    fetch(`https://vanko.herokuapp.com/categories/${id}`, {
      method: 'get',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
      .then(res => {
        if (!res.ok) throw { message: "Error" }
        return res.json()
      })
      .then(data => dispatch(fetchCategorySuccess(data)))
      .catch(console.log)
  }
}

const editCategory = (id, name) => {
  return async (dispatch) => {
    return await fetch(`https://vanko.herokuapp.com/categories/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify({ name })
    })
  }
}

const fetchCategoriesSuccess = (data) => {
  return {
    type: FETCH_CATEGORIES,
    data: data
  }
}

const fetchCategorySuccess = (data) => {
  return {
    type: FETCH_CATEGORY,
    data: data
  }
}

export {
  fetchCategories,
  deleteCategory,
  addCategory,
  fetchCategory,
  editCategory
}