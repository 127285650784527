import { FETCH_CATEGORIES, FETCH_CATEGORY } from "../actions/actionType"

const initialState = { categories: [], category: {}}

function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return { ...state, categories: action.data }
    case FETCH_CATEGORY:
      return { ...state, category: action.data }
    default:
      return state
  }
}

export default categoriesReducer