const TableRowCategory = ({ deleteCategory, editCategory, data, num }) => {
  const { id, name, createdAt, updatedAt } = data
  return (
    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
      <th className="text-center">
        {num}
      </th>
      <td className="text-center">
        <p>{name}</p>
      </td>
      <td className="text-center">
        <p className="font-medium">{createdAt.split("T")[0]}</p>
      </td>
      <td className="text-center">
        <p className="font-medium">{updatedAt.split("T")[0]}</p>
      </td>
      <td className="text-center space-x-2">
        <button
          onClick={() => editCategory(id)}
          className="px-2 py-1.5 rounded bg-amber-500 hover:bg-amber-400 text-white text-sm">
          Edit
        </button>
        <button
          onClick={() => deleteCategory(id)}
          className="px-2 py-1.5 rounded bg-red-600 hover:bg-red-500 text-white text-sm">
          Delete
        </button>
      </td>
    </tr>
  )
}

export default TableRowCategory