const Navbar = () => {
    return(
        <div className="w-full h-20 bg-white fixed flex items-center px-10 border-l">
            <div className="flex-1">
                <p className="text-2xl font-semibold">Vanko</p>
            </div>
        </div>
    )
}

export default Navbar