const fetchImages = (productId) => {
  return (dispatch) => {
    return fetch(`https://vanko.herokuapp.com/images/${productId}`, {
      method: 'get',
      headers: {
        access_token: localStorage.getItem('access_token')
      }
    })
  }
}

const editImage = (imageId, imgUrl) => {
  return (dispatch) => {
    console.log(imgUrl);
    return fetch(`https://vanko.herokuapp.com/images/${imageId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.getItem('access_token')
      },
      body: JSON.stringify({ imgUrl }),
    })
  }
}

export { fetchImages, editImage }