import { Outlet } from 'react-router-dom'
import Navbar from '../component/Navbar';
import Sidebar from '../component/Sidebar';

const Dashboard = () => {
  return (
    <div>
      <div className="flex flex-no-wrap">
        <Sidebar />
        <div className="container mx-auto h-screen w-11/12">
            <Navbar />
          <div className="w-full h-full rounded bg-slate-100">
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard