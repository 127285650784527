import { FETCH_PRODUCT, FETCH_PRODUCTS } from "../actions/actionType"

const initialState = { products: [], product: {}}

function productsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return { ...state, products: action.data }
    case FETCH_PRODUCT:
      return { ...state, product: action.data }
    default:
      return state
  }
}

export default productsReducer